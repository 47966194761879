import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout
    homepage={true}
    headerTitle="Not Found"
    headerDescription="You just hit a page that doesn&#39;t exist... the sorrow!"
  >
    <SEO title="404: Not found" />
  </Layout>
)

export default NotFoundPage
